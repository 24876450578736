@charset "utf-8";

// サイトマップ
.sitemap-flexbox {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & > div {
    @media #{$mq-sm} {
      flex-basis: calc(50% - 10px);
    }
    @media #{$mq-md} {
      flex-basis: calc(50% - 20px);
    }
  }
}
.contents-block.sitemap {
  h4 {
    margin-bottom: 10px;
  }
  ul {
    margin-bottom: $margin-sm;
    li {
      margin: 5px 0;
      width: 100%;
      list-style-type: none;
    }
  }
}