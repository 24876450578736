// Color Variables

$margin-lg: 80px;
$margin-md: 60px;
$margin-sm: 30px;

$bg: #fff; // Background color

$base: #333; // Base text color
$light: #999; // Light text color

$link: #333; // Link text color
$hover: lighten($link,12%); // Link text color(hover)

$border: #C8C8C8; // Border color

$gray: #9b9b9b;
$lightgray: #e9e9e9;
$red: #da2c28;
$blue: #28aada;
$turquoise: #48b7e0;
$yellow: #ffe20e;
$orange: #ff7e38;
$violet: #907ee9;
$pink: #df5b7d;
$aquamarine: #47c6b9;
$lime: #aada28;


// Smartphone - PC
$mq-xs: '(max-width: 480px)'; // Media Queries - Smartphone

$mq-sm: '(min-width: 600px)'; // Media Queries - Small tablet & smartphone

$mq-md: '(min-width: 960px)'; // Media Queries - Tablet

$mq-lg: '(min-width: 1240px)'; // Media queries - Large display

// Media Queries - Retina display
$at2x: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 2dppx)"; // All retina devices

$at2x-tablet: "(-webkit-min-device-pixel-ratio: 2) and (min-device-width: 600px),(min-resolution: 2dppx) and (min-device-width: 600px)"; // Larger than iPad display

$at2x-pc: "(-webkit-min-device-pixel-ratio: 2) and (min-device-width: 960px),(min-resolution: 2dppx) and (min-device-width: 960px)"; // Larger than PC display (1025px)
