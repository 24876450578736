@charset "UTF-8";
.head a:hover {
  cursor: pointer; }

.pan br {
  display: none; }

#pro_index .title {
  cursor: pointer;
  background: #9b9b9b url("/images/second/index_service_btn_off.png") calc(100% - 12px) center no-repeat; }

#pro_index .panel {
  display: none; }

#pro_index .title.active {
  background: #9b9b9b url("/images/second/index_service_btn_on.png") calc(100% - 12px) center no-repeat; }

.topics-info {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .topics-info span.date {
    margin: 0;
    padding: 0;
    background: none;
    color: #999;
    letter-spacing: 1px; }

@media (min-width: 600px) {
  .field_list .link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (min-width: 600px) {
  .field_list .link div {
    flex-basis: calc(50% - 5px); } }

@media (min-width: 600px) {
  .bnr3 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -4px;
    margin-left: -4px; } }

@media (min-width: 600px) {
  .bnr3 a {
    margin: 0 4px 15px 4px; } }

@media (min-width: 600px) {
  .pro_index .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (min-width: 600px) {
  .pro_index .flexbox > div {
    flex-basis: calc(50% - 15px); } }

@media (min-width: 600px) {
  .field_index .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (min-width: 600px) {
  .field_index .flexbox > div {
    flex-basis: calc(50% - 15px); } }

@media (min-width: 600px) {
  .app_index .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -15px;
    margin-left: -15px; } }

@media (min-width: 600px) {
  .app_index .flexbox > div {
    flex-basis: calc(33.33% - 30px);
    margin-right: 15px;
    margin-left: 15px; } }

.button {
  display: inline-block;
  text-decoration: none; }
  .button:hover {
    text-decoration: underline; }

.button_back {
  padding: 8px 20px;
  border: solid 1px #28aada;
  color: #28aada;
  letter-spacing: 2px;
  font-size: 14px; }
  .button_back i, .button_back span.icon {
    margin-right: 10px; }

.button-404 {
  border: 1px solid #28aada;
  margin: 10px;
  padding: 8px 30px;
  color: #28aada; }
  .button-404 img {
    margin-left: 10px; }

.button-download {
  border: 1px solid #ff7e38;
  padding: 10px 2em;
  font-size: 13px; }
  .button-download i, .button-download span.icon {
    margin-left: 10px;
    color: #ff7e38; }

.ser_sp.no-icon {
  background-image: none; }
  .ser_sp.no-icon.accordion-on {
    background-image: none; }

.side2 p,
text-warning {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px; }

.link_back {
  margin-top: 30px; }
  @media (min-width: 960px) {
    .link_back {
      margin-top: 60px; } }

h3.construction-headline {
  margin-bottom: 30px;
  padding: 16px 6px;
  border-bottom: solid 1px #C8C8C8;
  text-align: center;
  font-size: 17px; }
  @media (min-width: 600px) {
    h3.construction-headline {
      text-align: left; } }

p.default {
  font-size: 13px; }
  @media (min-width: 960px) {
    p.default {
      font-size: 14px; } }

.software_download_list {
  margin-top: 30px; }
  @media (min-width: 600px) {
    .software_download_list {
      margin-top: 60px; } }

.revision_up {
  margin-bottom: 30px; }
  @media (min-width: 600px) {
    .revision_up {
      margin-bottom: 40px; } }
  .revision_up .title {
    margin-bottom: 5px; }
    @media (min-width: 600px) {
      .revision_up .title {
        margin-bottom: 1em;
        overflow: hidden; } }
    .revision_up .title a {
      display: block;
      color: #333;
      font-weight: bold;
      font-size: 16px; }
      @media (min-width: 600px) {
        .revision_up .title a {
          float: right;
          width: calc(100% - 100px); } }
      .revision_up .title a:hover {
        text-decoration: none; }
    .revision_up .title span.title-text {
      display: block;
      color: #333;
      font-weight: bold;
      font-size: 16px; }
      @media (min-width: 600px) {
        .revision_up .title span.title-text {
          float: right;
          width: calc(100% - 100px); } }
  .revision_up .label {
    display: inline-block;
    margin-top: 3px;
    margin-bottom: 5px;
    padding: 2px 10px;
    border: none;
    vertical-align: middle;
    letter-spacing: 1px;
    font-size: 68.75%; }
    @media (min-width: 600px) {
      .revision_up .label {
        margin-right: 15px;
        margin-bottom: 0; } }
  .revision_up p {
    font-size: 12px; }
    @media (min-width: 600px) {
      .revision_up p {
        font-size: 13px; } }
  .revision_up table.table-list1 {
    width: 100%; }
    @media (min-width: 960px) {
      .revision_up table.table-list1 {
        font-size: 13px; } }
    .revision_up table.table-list1 th, .revision_up table.table-list1 td {
      border: 1px solid #C8C8C8;
      padding: 10px 5px; }
      @media (min-width: 960px) {
        .revision_up table.table-list1 th, .revision_up table.table-list1 td {
          text-align: center; } }
    .revision_up table.table-list1 td {
      font-size: 15px;
      word-wrap: break-word; }
      @media (min-width: 960px) {
        .revision_up table.table-list1 td {
          font-size: 13px; } }
    .revision_up table.table-list1 span {
      display: inline;
      margin-left: 10px; }
      @media (min-width: 960px) {
        .revision_up table.table-list1 span {
          display: block;
          margin: 0;
          font-size: 12px; } }
    @media (min-width: 960px) {
      .revision_up table.table-list1 thead th:nth-child(1) {
        width: 25%; }
      .revision_up table.table-list1 thead th:nth-child(2) {
        width: 18%; }
      .revision_up table.table-list1 thead th:nth-child(3) {
        width: 24%; }
      .revision_up table.table-list1 thead th:nth-child(4) {
        width: 13%; }
      .revision_up table.table-list1 thead th:nth-child(5) {
        width: 20%; } }
  .revision_up .table-list1 {
    border-collapse: collapse; }
  .revision_up .table-list1 thead th {
    background: #e9e9e9; }
  @media only screen and (max-width: 800px) {
    .revision_up .table-list1 {
      display: block; }
    .revision_up .table-list1 thead {
      display: none; }
    .revision_up .table-list1 tbody {
      display: block; }
    .revision_up .table-list1 tbody tr {
      display: block;
      margin-top: 10px;
      margin-bottom: 20px; }
    .revision_up .table-list1 tbody th,
    .revision_up .table-list1 tbody td {
      display: list-item;
      list-style-type: none;
      border: none; }
    .revision_up .table-list1 tbody th {
      margin-bottom: 10px;
      padding: 5px;
      margin-bottom: 10px;
      list-style-type: none;
      background: #e9e9e9; }
    .revision_up .table-list1 tbody td {
      padding: 0; }
    .revision_up .table-list1 tbody td:nth-of-type(1):before {
      content: "適用するソフトウェア：";
      font-weight: bold; }
    .revision_up .table-list1 tbody td:nth-of-type(2):before {
      content: "ダウンロード用ファイル：";
      font-weight: bold; }
    .revision_up .table-list1 tbody td:nth-of-type(3):before {
      content: "発行日：";
      font-weight: bold; }
    .revision_up .table-list1 tbody td:nth-of-type(4):before {
      content: "インストールガイド：";
      font-weight: bold; } }

.download-information {
  margin-bottom: 60px; }
  @media (min-width: 960px) {
    .download-information {
      margin-bottom: 60px; } }
  .download-information .button-group {
    margin-top: 30px; }
    @media (min-width: 600px) {
      .download-information .button-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    .download-information .button-group div {
      margin-bottom: 15px;
      width: 100%; }
      @media (min-width: 600px) {
        .download-information .button-group div {
          flex-basis: calc(50% - 5px); } }
      .download-information .button-group div span {
        font-size: 13px; }
    .download-information .button-group a {
      display: block;
      padding: 14px 20px;
      border: solid 1px #C8C8C8;
      background: url("/images/icon_arrow2.png") calc(100% - 14px) 50% no-repeat;
      text-decoration: none; }
      .download-information .button-group a:hover {
        text-decoration: underline; }
    .download-information .button-group p {
      font-size: 12px;
      margin-top: 10px; }
    .download-information .button-group span.title {
      font-weight: bold; }

@media (min-width: 600px) {
  .button-group.download {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

.button-group.download div {
  margin-bottom: 15px;
  width: 100%; }
  @media (min-width: 600px) {
    .button-group.download div {
      flex-basis: calc(33.3% - 5px); } }
  .button-group.download div span {
    font-size: 13px; }

.button-group.download a {
  display: block;
  padding: 14px 20px;
  border: solid 1px #C8C8C8;
  background: url("/images/icon_arrow2.png") calc(100% - 14px) 50% no-repeat;
  text-decoration: none; }
  .button-group.download a:hover {
    text-decoration: underline; }

@media (min-width: 960px) {
  .app_link.long a {
    width: 45%; } }

.software_download_list .text-block.text-option {
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #28aada;
  text-align: center;
  word-wrap: break-word;
  font-size: 14px; }

.software_download_list .text-block-cf {
  font-size: 12px; }

.revision-content {
  margin-bottom: 30px;
  font-size: 14px; }
  .revision-content .border-box {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #C8C8C8; }

.table-list2 {
  margin-top: 10px;
  width: 100%; }
  @media (min-width: 600px) {
    .table-list2 th, .table-list2 td {
      display: table-cell;
      padding: 10px;
      border: 1px solid #C8C8C8; } }
  .table-list2 th {
    display: block;
    margin-bottom: 10px;
    padding: 5px;
    background-color: #e9e9e9; }
  .table-list2 td {
    display: block;
    border: none; }
    .table-list2 td:last-child {
      margin-bottom: 20px; }

.pro_index.pdf-list .link-block {
  margin-bottom: 60px; }
  @media (min-width: 600px) {
    .pro_index.pdf-list .link-block {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 600px) {
    .pro_index.pdf-list .link-block a {
      flex-basis: calc(50% - 15px); } }

.contents-block.download-gps p.border-box {
  padding: 20px;
  border: 1px solid #C8C8C8;
  font-size: 14px; }

.contents-block.jsima .logo {
  margin-bottom: 30px; }

.contents-block.jsima p {
  margin-bottom: 40px; }

.download-footer {
  margin-top: 30px; }
  @media (min-width: 960px) {
    .download-footer {
      margin-top: 60px; } }

.table-gnssapp {
  width: 100%;
  font-size: 13px;
  margin-top: 30px; }
  @media (min-width: 600px) {
    .table-gnssapp {
      font-size: 14px; } }
  @media (min-width: 600px) {
    .table-gnssapp th, .table-gnssapp td {
      border: 1px solid #C8C8C8;
      padding: 8px 5px;
      text-align: center; } }
  .table-gnssapp thead {
    display: none; }
    @media (min-width: 600px) {
      .table-gnssapp thead {
        display: table-row-group;
        background: #e9e9e9; } }
  .table-gnssapp tbody tr + tr {
    border-top: 1px solid #C8C8C8; }
    @media (min-width: 600px) {
      .table-gnssapp tbody tr + tr {
        border-top: none; } }
  .table-gnssapp tbody td {
    display: block; }
    @media (min-width: 600px) {
      .table-gnssapp tbody td {
        display: table-cell; } }
  .table-gnssapp tbody td:nth-of-type(1):before {
    content: "受信機：";
    font-weight: bold; }
    @media (min-width: 600px) {
      .table-gnssapp tbody td:nth-of-type(1):before {
        display: none; } }
  .table-gnssapp tbody td:nth-of-type(2):before {
    content: "クイックリファレンス：";
    font-weight: bold; }
    @media (min-width: 600px) {
      .table-gnssapp tbody td:nth-of-type(2):before {
        display: none; } }
  .table-gnssapp tbody td:nth-of-type(3):before {
    content: "User Guide：";
    font-weight: bold; }
    @media (min-width: 600px) {
      .table-gnssapp tbody td:nth-of-type(3):before {
        display: none; } }
  .table-gnssapp tbody td:first-child {
    padding-top: 10px; }
    @media (min-width: 600px) {
      .table-gnssapp tbody td:first-child {
        padding-top: auto; } }
  .table-gnssapp tbody td:last-child {
    padding-bottom: 10px; }
    @media (min-width: 600px) {
      .table-gnssapp tbody td:last-child {
        padding-bottom: auto; } }

.text-gnssapp-option {
  margin-top: 1em;
  font-size: 13px; }

.gnssapp-download-link {
  margin-top: 30px; }

.table-officesoftware {
  width: 100%;
  font-size: 13px; }
  @media (min-width: 960px) {
    .table-officesoftware {
      font-size: 14px; } }
  .table-officesoftware thead {
    display: none; }
    @media (min-width: 960px) {
      .table-officesoftware thead {
        display: table-row-group;
        background-color: #e9e9e9; } }
  .table-officesoftware tr + tr {
    border-top: 1px solid #C8C8C8; }
    @media (min-width: 960px) {
      .table-officesoftware tr + tr {
        border: none; } }
  @media (min-width: 960px) {
    .table-officesoftware th, .table-officesoftware td {
      padding: 10px;
      border: 1px solid #C8C8C8;
      text-align: center; } }
  .table-officesoftware td {
    display: block; }
    @media (min-width: 960px) {
      .table-officesoftware td {
        display: table-cell; } }
  .table-officesoftware td:nth-of-type(1):before {
    content: "用途：";
    font-weight: bold; }
    @media (min-width: 960px) {
      .table-officesoftware td:nth-of-type(1):before {
        display: none; } }
  .table-officesoftware td:nth-of-type(2):before {
    content: "適用するソフトウェア：";
    font-weight: bold; }
    @media (min-width: 960px) {
      .table-officesoftware td:nth-of-type(2):before {
        display: none; } }
  .table-officesoftware td:nth-of-type(3):before {
    content: "ダウンロード用ファイル：";
    font-weight: bold; }
    @media (min-width: 960px) {
      .table-officesoftware td:nth-of-type(3):before {
        display: none; } }
  .table-officesoftware td:nth-of-type(4):before {
    content: "発行日：";
    font-weight: bold; }
    @media (min-width: 960px) {
      .table-officesoftware td:nth-of-type(4):before {
        display: none; } }
  .table-officesoftware td:nth-of-type(5):before {
    content: "インストールガイド：";
    font-weight: bold; }
    @media (min-width: 960px) {
      .table-officesoftware td:nth-of-type(5):before {
        display: none; } }
  .table-officesoftware td:first-child {
    padding-top: 10px; }
  .table-officesoftware td:last-child {
    padding-bottom: 10px; }
  .table-officesoftware .text-sm {
    font-size: 11px; }
    @media (min-width: 960px) {
      .table-officesoftware .text-sm {
        font-size: 12px; } }

.text-download-lead {
  font-size: 14px; }

.contents.setsuzoku p {
  font-size: 14px; }

.setsuzoku-flex-block {
  margin-top: 30px;
  font-size: 14px; }
  @media (min-width: 600px) {
    .setsuzoku-flex-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .setsuzoku-flex-block > div {
    margin-top: 20px; }
    @media (min-width: 600px) {
      .setsuzoku-flex-block > div {
        width: calc(50% - 15px); } }
  .setsuzoku-flex-block .order1 {
    order: -2; }
  .setsuzoku-flex-block .order2 {
    order: -1; }
  .setsuzoku-flex-block h4 {
    margin-bottom: 5px; }
  .setsuzoku-flex-block dd {
    padding-left: 1.5em;
    font-size: 13px; }

.table-block {
  overflow: auto;
  white-space: nowrap; }

.table-setsuzoku {
  margin-top: 30px;
  width: 100%; }
  @media (min-width: 600px) {
    .table-setsuzoku {
      margin-top: 60px; } }
  .table-setsuzoku th, .table-setsuzoku td {
    padding: 10px;
    border: 1px solid #C8C8C8;
    font-size: 14px; }
  .table-setsuzoku th {
    background: #e9e9e9; }

.select-setsuzoku {
  margin-top: 30px; }
  @media (min-width: 600px) {
    .select-setsuzoku {
      margin-top: 60px; } }
  .select-setsuzoku select {
    width: 100%; }
    @media (min-width: 600px) {
      .select-setsuzoku select {
        width: 30%; } }

.table-company th {
  display: block;
  padding: 3px 0;
  text-align: left; }
  @media (min-width: 600px) {
    .table-company th {
      display: table-cell;
      padding: 10px;
      vertical-align: top;
      text-align: right; } }

.table-company td {
  display: block;
  padding: 3px 0; }
  @media (min-width: 600px) {
    .table-company td {
      display: table-cell;
      padding: 10px; } }

.table-company-english {
  width: 100%; }
  @media (min-width: 600px) {
    .table-company-english th {
      width: 32%; } }

.base-group {
  overflow: hidden;
  margin-bottom: 60px; }
  .base-group img {
    max-width: 100%; }
  .base-group h4 {
    font-size: 16px;
    margin: 10px 0; }
    @media (min-width: 600px) {
      .base-group h4 {
        margin-top: 0; } }
  @media (min-width: 600px) {
    .base-group > div {
      float: left; } }
  @media (min-width: 600px) {
    .base-group > div:nth-of-type(1) {
      width: calc(40% - 10px); } }
  @media (min-width: 600px) {
    .base-group > div:nth-of-type(2) {
      width: calc(60% - 10px);
      margin-left: 20px; } }

.table-business {
  margin-top: 1em;
  width: 100%; }
  .table-business th, .table-business td {
    display: block;
    padding: 5px;
    font-size: 13px; }
    @media (min-width: 600px) {
      .table-business th, .table-business td {
        display: table-cell;
        padding: 10px;
        border: 1px solid #C8C8C8; } }
  .table-business th {
    text-align: left;
    background-color: #f5f5f5; }
    @media (min-width: 600px) {
      .table-business th {
        width: 25%;
        vertical-align: top; } }

.business-grid > div {
  margin-bottom: 40px; }

.contents-block .history {
  margin-bottom: 30px; }

.googlemap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden; }
  @media (min-width: 600px) {
    .googlemap {
      overflow: initial;
      height: 40px; } }
  @media (min-width: 960px) {
    .googlemap {
      height: 80px; } }

.googlemap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.english-doc-block ul {
  display: none; }
  .english-doc-block ul.top-list {
    margin-bottom: 30px; }

.english-doc-block .doc-title {
  margin-bottom: 10px;
  padding: 10px;
  background: #e9e9e9 url("/images/second/index_service_btn_off.png") top 50% right 10px no-repeat;
  cursor: pointer; }
  .english-doc-block .doc-title.active {
    background: #e9e9e9 url("/images/second/index_service_btn_on.png") top 50% right 10px no-repeat; }

.english-doc-block li {
  position: relative;
  margin: 10px 0 10px 1em;
  padding-left: 1.6em;
  list-style-type: none;
  cursor: pointer; }
  .english-doc-block li:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 15px;
    height: 15px;
    background: url("/images/english/english-off.png") 0% 50% no-repeat;
    content: ""; }
  .english-doc-block li.active:before {
    background: url("/images/english/english-on.png") 0% 50% no-repeat; }

.english-doc-block i.fa-file-pdf-o, .english-doc-block span.fa-file-pdf-o {
  margin-left: 10px;
  color: #da2c28; }

.english-doc-block ul > li > ul > li > ul > li {
  padding-left: 0; }
  .english-doc-block ul > li > ul > li > ul > li:before {
    background: none; }

.content-recruit {
  margin-bottom: 30px; }
  .content-recruit .text-sm {
    font-size: 12px; }

.image-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }
  @media (min-width: 600px) {
    .image-group {
      justify-content: space-between;
      margin: 0 -5px 0 -5px; } }
  .image-group > div {
    flex-basis: calc(50% - 5px);
    margin-top: 20px;
    text-align: center; }
    @media (min-width: 600px) {
      .image-group > div {
        flex: 2 0 calc(33.3% - 10px);
        margin-right: 5px;
        margin-left: 5px; } }
  .image-group img {
    max-width: 100%; }
  .image-group p {
    margin-top: 0.5em;
    font-size: 12px; }

.table-recruit {
  margin-top: 30px; }
  .table-recruit th, .table-recruit td {
    display: block;
    padding: 5px;
    text-align: left; }
    @media (min-width: 600px) {
      .table-recruit th, .table-recruit td {
        display: table-cell;
        padding: 10px; } }
  .table-recruit th {
    background-color: #e9e9e9; }
    @media (min-width: 600px) {
      .table-recruit th {
        width: 26%;
        background-color: #fff;
        vertical-align: top; } }
    @media (min-width: 960px) {
      .table-recruit th {
        width: 32%; } }
  @media (min-width: 600px) {
    .table-recruit th.narrow {
      width: 20%; } }

@media (min-width: 600px) {
  .occupation-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (min-width: 600px) {
  .occupation-flex > div {
    flex-basis: calc(50% - 10px);
    margin-bottom: 30px; } }

@media (min-width: 960px) {
  .occupation-flex > div {
    flex-basis: calc(50% - 20px); } }

@media (min-width: 600px) {
  .faq-flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (min-width: 600px) {
  .faq-flexbox > div {
    flex-basis: calc(50% - 10px); } }

@media (min-width: 960px) {
  .faq-flexbox > div {
    flex-basis: calc(50% - 20px); } }

.faq-flexbox a {
  display: block;
  margin-top: 8px;
  font-size: 13px; }

.contents-block.faq .text-q {
  display: inline-block;
  margin-right: 8px;
  color: #28aada; }

.contents-block.faq .text-a {
  display: inline-block;
  margin-right: 8px;
  color: #df5b7d; }

.contents-block.faq .faq-group + .faq-group {
  margin-top: 30px; }

.contact-mail-block {
  padding: 20px 0;
  border-top: 1px solid #28aada;
  border-bottom: 1px solid #28aada; }
  @media (min-width: 960px) {
    .contact-mail-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around; } }
  .contact-mail-block h3 {
    text-align: center;
    font-size: 18px; }
    @media (min-width: 960px) {
      .contact-mail-block h3 {
        text-align: left; } }
  .contact-mail-block .mailaddress {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.2; }
    @media (min-width: 960px) {
      .contact-mail-block .mailaddress {
        margin-top: 5px; } }
    .contact-mail-block .mailaddress span {
      display: inline-block;
      margin-right: 10px;
      font-size: 57.1%; }
  .contact-mail-block p {
    font-size: 12px; }
  .contact-mail-block div + div {
    margin-top: 20px; }
    @media (min-width: 960px) {
      .contact-mail-block div + div {
        margin-top: 0; } }

.contact-tel-block {
  margin-top: 30px; }
  @media (min-width: 600px) {
    .contact-tel-block {
      margin-top: 60px; } }
  .contact-tel-block h3 {
    font-size: 16px; }
  .contact-tel-block h4 {
    margin: 20px 0 1em 0;
    font-size: 14px; }
  .contact-tel-block i, .contact-tel-block span.icon {
    display: inline-block;
    margin: 0 5px 0 10px; }
  .contact-tel-block .title {
    color: #28aada; }

.privacy-block {
  margin-top: 30px; }
  .privacy-block h3 {
    margin: 10px 0;
    padding: 0 5px 10px 5px;
    border-bottom: 1px solid #9b9b9b; }
  .privacy-block p {
    margin-top: 20px; }

.privacy-list {
  margin: 1em 0; }
  .privacy-list li {
    margin: 5px 0;
    padding-left: 1.5em;
    list-style-type: none;
    background: url("/images/icon_arrow2.png") top 5px left 5px no-repeat; }

@media (min-width: 600px) {
  .sitemap-flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (min-width: 600px) {
  .sitemap-flexbox > div {
    flex-basis: calc(50% - 10px); } }

@media (min-width: 960px) {
  .sitemap-flexbox > div {
    flex-basis: calc(50% - 20px); } }

.contents-block.sitemap h4 {
  margin-bottom: 10px; }

.contents-block.sitemap ul {
  margin-bottom: 30px; }
  .contents-block.sitemap ul li {
    margin: 5px 0;
    width: 100%;
    list-style-type: none; }

@media (min-width: 960px) {
  .topi_box_flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

a {
  color: #333; }
  a:hover {
    text-decoration: none; }
  a img {
    text-decoration: none; }

@media (min-width: 960px) {
  .sp-navi {
    display: none; } }

.contents-block {
  margin-bottom: 60px;
  font-size: 13px; }
  @media (min-width: 960px) {
    .contents-block {
      margin-bottom: 80px;
      font-size: 14px; } }

.pdf i, .pdf span.icon {
  color: #dd0000;
  margin: 0 10px; }

.link i, .link span.icon {
  color: #28aada;
  margin: 0 10px; }

.anker {
  display: block;
  margin-top: -100px;
  padding-top: 100px; }

.glay_back {
  margin-bottom: 10px;
  padding: 12px;
  background-color: #9b9b9b;
  color: #fff;
  letter-spacing: 1px;
  font-size: 15px; }
  @media (min-width: 600px) {
    .glay_back {
      padding: 14px 12px;
      font-size: 16px; } }

.gray_border {
  border-bottom: solid 1px #C8C8C8;
  text-align: center;
  padding: 16px 6px;
  margin-bottom: 30px;
  font-size: 17px; }
  @media (min-width: 960px) {
    .gray_border {
      text-align: left; } }

.border_dotted {
  border-bottom: dotted 1px #9b9b9b;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 5px; }
  @media (min-width: 600px) {
    .border_dotted {
      margin-bottom: 20px; } }

.margin_headline {
  margin: 10px 0 20px 0; }

.label.label-yellow {
  background-color: #ffe20e; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-red {
  color: #da2c28; }

.text-blue {
  color: #28aada; }

.text-turquoise {
  color: #48b7e0; }

.text-yellow {
  color: #ffe20e; }

.text-orange {
  color: #ff7e38; }

.text-violet {
  color: #907ee9; }

.text-pink {
  color: #df5b7d; }

.text-aquamarine {
  color: #47c6b9; }

.error404-block {
  text-align: center; }
  .error404-block .text-en {
    font-size: 12px; }
  .error404-block .button-group {
    margin-top: 30px; }
