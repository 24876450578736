@charset "utf-8";

// contact
.contact-mail-block {
  padding: 20px 0;
  border: {
    top: 1px solid $blue;
    bottom: 1px solid $blue;
  };
  @media #{$mq-md} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  h3 {
    text-align: center;
    font-size: 18px;
    @media #{$mq-md} {
      text-align: left;
    }
  }
  .mailaddress {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.2;
    @media #{$mq-md} {
      margin-top: 5px;
    }
    span {
      display: inline-block;
      margin-right: 10px;
      font-size: 57.1%;
    }
  }
  p {
    font-size: 12px;
  }
  div + div {
    margin-top: 20px;
    @media #{$mq-md} {
      margin-top: 0;
    }
  }
}
.contact-tel-block {
  margin-top: $margin-sm;
  @media #{$mq-sm} {
    margin-top: $margin-md;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    margin: 20px 0 1em 0;
    font-size: 14px;
  }
  i, span.icon {
    display: inline-block;
    margin: 0 5px 0 10px;
  }
  .title {
    color: $blue;
  }
}