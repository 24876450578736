@charset "utf-8";

.english-doc-block {
  ul {
    display: none;
    &.top-list {
      margin-bottom: $margin-sm;
    }
  }

  .doc-title {
    margin-bottom: 10px;
    padding: 10px;
    background: $lightgray url('/images/second/index_service_btn_off.png') top 50% right 10px no-repeat;
    cursor: pointer;
    &.active {
      background: $lightgray url('/images/second/index_service_btn_on.png') top 50% right 10px no-repeat;
    }
  }

  li {
    position: relative;
    margin: 10px 0 10px 1em;
    padding-left: 1.6em;
    list-style-type: none;
    cursor: pointer;
    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      width: 15px;
      height: 15px;
      background: url('/images/english/english-off.png') 0% 50% no-repeat;
      content:"";
    }
    &.active:before {
      background: url('/images/english/english-on.png') 0% 50% no-repeat;
    }
  }
  i.fa-file-pdf-o, span.fa-file-pdf-o {
    margin-left: 10px;
    color: $red;
  }

  // 最後の階層はpdfのリンクになるのでBGを消す
  ul > li > ul > li > ul > li {
    padding-left: 0;
    &:before {
      background: none;
    }
  }
}