@charset "utf-8";
// support/sidebar
.ser_sp.no-icon {
  background-image: none;
  &.accordion-on {
    background-image: none;
  }
}

.side2 p,
text-warning {
  font-size: 12px;
  margin: {
    top: 5px;
    bottom: 10px;
  }
  ;
}

// supprt/software_download
.link_back {
  margin-top: $margin-sm;
  @media #{$mq-md} {
    margin-top: $margin-md;
  }
}

h3.construction-headline {
  margin-bottom: 30px;
  padding: 16px 6px;
  border-bottom: solid 1px $border;
  text-align: center;
  font-size: 17px;
  @media #{$mq-sm} {
    text-align: left;
  }
}

p {
  &.default {
    font-size: 13px;
    @media #{$mq-md} {
      font-size: 14px;
    }
  }
}

.software_download_list {
  margin-top: $margin-sm;
  @media #{$mq-sm} {
    margin-top: $margin-md;
  }
}

.revision_up {
  margin-bottom: 30px;
  @media #{$mq-sm} {
    margin-bottom: 40px;
  }
  .title {
    margin-bottom: 5px;
    @media #{$mq-sm} {
      margin-bottom: 1em;
      overflow: hidden;
    }
    a {
      display: block;
      color: $base;
      font-weight: bold;
      font-size: 16px;
      @media #{$mq-sm} {
        float: right;
        width: calc(100% - 100px);
      }
      &:hover {
        text-decoration: none;
      }
    }
    span.title-text {
      display: block;
      color: $base;
      font-weight: bold;
      font-size: 16px;
      @media #{$mq-sm} {
        float: right;
        width: calc(100% - 100px);
      }
    }
  }
  .label {
    display: inline-block;
    margin: {
      top: 3px;
      bottom: 5px;
    };
    padding: 2px 10px;
    border: none;
    vertical-align: middle;
    letter-spacing: 1px;
    font-size: 68.75%;
    @media #{$mq-sm} {
      margin: {
        right: 15px;
        bottom: 0;
      };
    }
  }
  p {
    font-size: 12px;
    @media #{$mq-sm} {
      font-size: 13px;
    }
  }
  table.table-list1 {
    width: 100%;
    @media #{$mq-md} {
      font-size: 13px;
    }
    th, td {
      border: 1px solid $border;
      padding: 10px 5px;
      @media #{$mq-md} {
        text-align: center;
      }
    }
    td {
      font-size: 15px;
      word-wrap: break-word;
      @media #{$mq-md} {
        font-size: 13px;
      }
    }
    span {
      display: inline;
      margin-left: 10px;
      @media #{$mq-md} {
        display: block;
        margin: 0;
        font-size: 12px;
      }
    }
    @media #{$mq-md} {
      thead th:nth-child(1) { width: 25%; }
      thead th:nth-child(2) { width: 18%; }
      thead th:nth-child(3) { width: 24%; }
      thead th:nth-child(4) { width: 13%; }
      thead th:nth-child(5) { width: 20%; }
    }
  }

  // Response Table
  .table-list1 {
    border-collapse: collapse;
  }
  .table-list1 thead th {
    background: $lightgray;
  }
  @media only screen and (max-width: 800px) {
    .table-list1 {
      display: block;
    }
    .table-list1 thead {
      display: none;
    }
    .table-list1 tbody {
      display: block;
    }
    .table-list1 tbody tr {
      display: block;
      margin: {
        top: 10px;
        bottom: 20px;
      };
    }
    .table-list1 tbody th,
    .table-list1 tbody td {
      display: list-item;
      list-style-type: none;
      border: none;
    }
    .table-list1 tbody th {
      margin-bottom: 10px;
      padding: 5px;
      margin-bottom: 10px;
      list-style-type: none;
      background: $lightgray;
    }
    .table-list1 tbody td {
      padding: 0;
    }
    .table-list1 tbody td:nth-of-type(1):before {
      content: "適用するソフトウェア：";
      font-weight: bold;
    }
    .table-list1 tbody td:nth-of-type(2):before {
      content: "ダウンロード用ファイル：";
      font-weight: bold;
    }
    .table-list1 tbody td:nth-of-type(3):before {
      content: "発行日：";
      font-weight: bold;
    }
    .table-list1 tbody td:nth-of-type(4):before {
      content: "インストールガイド：";
      font-weight: bold;
    }
  }
}

// support/index
.download-information {
  margin-bottom: $margin-md;
  @media #{$mq-md} {
    margin-bottom: $margin-md;
  }
  .button-group {
    @include button-list;
    margin-top: $margin-sm;
    p {
      font-size: 12px;
      margin-top: 10px;
    }
    span.title {
      font-weight: bold;
    }
  }
}

// download
.button-group.download {
  @include button-list(33.3%);
}

// GNSS受信機 gps.html
.app_link.long a {
  @media #{$mq-md} {
    width: 45%;
  }
}
.software_download_list .text-block.text-option {
  margin: {
    top: $margin-lg;
    bottom: $margin-sm;
  };
  padding: 20px;
  border: 1px solid $blue;
  text-align: center;
  word-wrap: break-word;
  font-size: 14px;
}
.software_download_list .text-block-cf {
  font-size: 12px;
}

// 建築用製品　const.html
.revision-content {
  margin-bottom: $margin-sm;
  font-size: 14px;
  .border-box {
    margin: {
      top: 10px;
      bottom: 10px;
    };
    padding: 15px;
    border: 1px solid $border;
  }
}
.table-list2 {
  margin-top: 10px;
  width: 100%;
  th, td {
    @media #{$mq-sm} {
      display: table-cell;
      padding: 10px;
      border: 1px solid $border;
    }
  }
  th {
    display: block;
    margin-bottom: 10px;
    padding: 5px;
    background-color: $lightgray;
  }
  td {
    display: block;
    border: none;
    &:last-child {
      margin-bottom: 20px;
    }
  }
}

// カタログPDF一覧
.pro_index.pdf-list {
  .link-block {
    margin-bottom: $margin-md;
    @media #{$mq-sm} {
      display: flex;
      flex-wrap: wrap;
    }
    a {
      @media #{$mq-sm} {
        flex-basis: calc(50% - 15px);
      }
    }
  }
}

// GPS衛生情報 gps.html
.contents-block.download-gps {
  p.border-box {
    padding: 20px;
    border: 1px solid $border;
    font-size: 14px;
  }
}

// jsimaの規格について jsima.html
.contents-block.jsima {
  .logo {
    margin-bottom: $margin-sm;
  }
  p {
    margin-bottom: 40px;
  }
}

// ========================================================
// ダウンロード静的子ページ
// 測量用GNSS受信機
// ========================================================
// 受信機関連アプリケーション
.download-footer {
  margin-top: $margin-sm;
  @media #{$mq-md} {
    margin-top: $margin-md;
  }
}
.table-gnssapp {
  width: 100%;
  font-size: 13px;
  margin-top: $margin-sm;
  @media #{$mq-sm} {
    font-size: 14px;
  }
  th, td {
    @media #{$mq-sm} {
      border: 1px solid $border;
      padding: 8px 5px;
      text-align: center;
    }
  }
  thead {
    display: none;
    @media #{$mq-sm} {
      display: table-row-group;
      background: $lightgray;
    }
  }
  tbody {
    tr + tr {
      border-top: 1px solid $border;
      @media #{$mq-sm} {
        border-top: none;
      }
    }
    td {
      display: block;
      @media #{$mq-sm} {
        display: table-cell;
      }
    }
    td:nth-of-type(1):before {
      content: "受信機：";
      font-weight: bold;
      @media #{$mq-sm} {
        display: none;
      }
    }
    td:nth-of-type(2):before {
      content: "クイックリファレンス：";
      font-weight: bold;
      @media #{$mq-sm} {
        display: none;
      }
    }
    td:nth-of-type(3):before {
      content: "User Guide：";
      font-weight: bold;
      @media #{$mq-sm} {
        display: none;
      }
    }
    td:first-child {
      padding-top: 10px;
      @media #{$mq-sm} {
        padding-top: auto;
      }
    }
    td:last-child {
      padding-bottom: 10px;
      @media #{$mq-sm} {
        padding-bottom: auto;
      }
    }
  }
}
.text-gnssapp-option {
  margin-top: 1em;
  font-size: 13px;
}
.gnssapp-download-link {
  margin-top: $margin-sm;
}

// フィールド・オフィスソフトウェア関連アプリケーション
.table-officesoftware {
  width: 100%;
  font-size: 13px;
  @media #{$mq-md} {
    font-size: 14px;
  }
  thead {
    display: none;
    @media #{$mq-md} {
      display: table-row-group;
      background-color: $lightgray;
    }
  }
  tr + tr {
    border-top: 1px solid $border;
    @media #{$mq-md} {
      border: none;
    }
  }
  th, td {
    @media #{$mq-md} {
      padding: 10px;
      border: 1px solid $border;
      text-align: center;
    }
  }
  td {
    display: block;
    @media #{$mq-md} {
      display: table-cell;
    }
  }
  td:nth-of-type(1):before {
    content: "用途：";
    font-weight: bold;
    @media #{$mq-md} {
      display: none;
    }
  }
  td:nth-of-type(2):before {
    content: "適用するソフトウェア：";
    font-weight: bold;
    @media #{$mq-md} {
      display: none;
    }
  }
  td:nth-of-type(3):before {
    content: "ダウンロード用ファイル：";
    font-weight: bold;
    @media #{$mq-md} {
      display: none;
    }
  }
  td:nth-of-type(4):before {
    content: "発行日：";
    font-weight: bold;
    @media #{$mq-md} {
      display: none;
    }
  }
  td:nth-of-type(5):before {
    content: "インストールガイド：";
    font-weight: bold;
    @media #{$mq-md} {
      display: none;
    }
  }
  td:first-child {
    padding-top: 10px;
  }
  td:last-child {
    padding-bottom: 10px;
  }
  .text-sm {
    font-size: 11px;
    @media #{$mq-md} {
      font-size: 12px;
    }
  }
}

// ========================================================
// ダウンロード静的子ページ
// 建築用製品
// ========================================================
.text-download-lead {
  font-size: 14px;
}

// ========================================================
// 「トータルステーションを用いた出来形管理」接続確認情報
// ========================================================
.contents.setsuzoku {
  p {
    font-size: 14px;
  }
}
.setsuzoku-flex-block {
  margin-top: $margin-sm;
  font-size: 14px;
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & > div {
    margin-top: 20px;
    @media #{$mq-sm} {
      width: calc(50% - 15px);
    }
  }
  // 順序
  .order1 {
    order: -2;
  }
  .order2 {
    order: -1;
  }
  h4 {
    margin-bottom: 5px;
  }
  dd {
    padding-left: 1.5em;
    font-size: 13px;
  }
}
.table-block {
  overflow: auto;
  white-space: nowrap;
}
.table-setsuzoku {
  margin-top: $margin-sm;
  width: 100%;
  @media #{$mq-sm} {
    margin-top: $margin-md;
  }
  th, td {
    padding: 10px;
    border: 1px solid $border;
    font-size: 14px;
  }
  th {
    background: $lightgray;
  }
}
.select-setsuzoku {
  margin-top: $margin-sm;
  @media #{$mq-sm} {
    margin-top: $margin-md;
  }
  select {
    width: 100%;
    @media #{$mq-sm} {
      width: 30%;
    }
  }
}