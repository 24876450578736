@charset "utf-8";

.table-company {
  th {
    display: block;
    padding: 3px 0;
    text-align: left;
    @media #{$mq-sm} {
      display: table-cell;
      padding: 10px;
      vertical-align: top;
      text-align: right;
    }
  }
  td {
    display: block;
    padding: 3px 0;
    @media #{$mq-sm} {
      display: table-cell;
      padding: 10px;
    }
  }
}
.table-company-english {
  width: 100%;
  th {
    @media #{$mq-sm} {
      width: 32%;
    }
  }
}

// 会社概要 profile.html
.base-group {
  overflow: hidden;
  margin-bottom: $margin-md;

  img {
    max-width: 100%;
  }
  h4 {
    font-size: 16px;
    margin: 10px 0;
    @media #{$mq-sm} {
      margin-top: 0;
    }
  }

  & > div {
    @media #{$mq-sm} {
      float: left;
    }
  }
  & > div:nth-of-type(1) {
    @media #{$mq-sm} {
      width: calc(40% - 10px);
    }
  }
  & > div:nth-of-type(2) {
    @media #{$mq-sm} {
      width: calc(60% - 10px);
      margin-left: 20px;
    }
  }
}

// 事業内容
.table-business {
  margin-top: 1em;
  width: 100%;
  th, td {
    display: block;
    padding: 5px;
    font-size: 13px;
    @media #{$mq-sm} {
      display: table-cell;
      padding: 10px;
      border: 1px solid $border;
    }
  }
  th {
    text-align: left;
    background-color: #f5f5f5;
    @media #{$mq-sm} {
      width: 25%;
      vertical-align: top;
    }
  }
}
.business-grid {
  & > div {
    margin-bottom: 40px;
  }
}
.contents-block .history {
  margin-bottom: $margin-sm;
}

// googlemap
.googlemap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  @media #{$mq-sm} {
    overflow: initial;
    height: 40px;
  }
  @media #{$mq-md} {
    height: 80px;
  }
}
.googlemap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}