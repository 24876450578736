@charset "utf-8";

.button {
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.button_back {
  padding: 8px 20px;
  border: solid 1px $blue;
  color: $blue;
  letter-spacing: 2px;
  font-size: 14px;
  i, span.icon {
    margin-right: 10px;
  }
}
.button-404 {
  border: 1px solid $blue;
  margin: 10px;
  padding: 8px 30px;
  color: $blue;
  img {
    margin-left: 10px;
  }
}

// 基本は2列
@mixin button-list($button-width: 50%) {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  div {
    margin-bottom: 15px;
    width: 100%;
    @media #{$mq-sm} {
      flex-basis: calc(#{$button-width} - 5px);
    }
    span {
      font-size: 13px;
    }
  }
  a {
    display: block;
    padding: 14px 20px;
    border: solid 1px $border;
    background: url('/images/icon_arrow2.png') calc(100% - 14px) 50% no-repeat;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

// button-download
.button-download {
  border: 1px solid $orange;
  padding: 10px 2em;
  font-size: 13px;
  i, span.icon {
    margin-left: 10px;
    color: $orange;
  }
}