@charset "utf-8";

.content-recruit {
  margin-bottom: $margin-sm;
  .text-sm {
    font-size: 12px;
  }
}
.image-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media #{$mq-sm} {
    justify-content: space-between;
    margin: 0 -5px 0 -5px;
  }
  & > div {
    flex-basis: calc(50% - 5px);
    margin-top: 20px;
    text-align: center;
    @media #{$mq-sm} {
      flex: 2 0 calc(33.3% - 10px);
      margin: {
        right: 5px;
        left: 5px;
      };
    }
  }
  img {
    max-width: 100%;
  }
  p {
    margin-top: 0.5em;
    font-size: 12px;
  }
}
.table-recruit {
  margin-top: $margin-sm;
  th, td {
    display: block;
    padding: 5px;
    text-align: left;
    @media #{$mq-sm} {
      display: table-cell;
      padding: 10px;
    }
  }
  th {
    background-color: $lightgray;
    @media #{$mq-sm} {
      width: 26%;
      background-color: #fff;
      vertical-align: top;
    }
    @media #{$mq-md} {
      width: 32%;
    }
  }
  th.narrow {
    @media #{$mq-sm} {
      width: 20%;
    }
  }
}

// 職種/occupation
.occupation-flex {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & > div {
    @media #{$mq-sm} {
      flex-basis: calc(50% - 10px);
      margin-bottom: $margin-sm;
    }
    @media #{$mq-md} {
      flex-basis: calc(50% - 20px);
    }
  }
}

// よくある質問/faq
.faq-flexbox {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & > div {
    @media #{$mq-sm} {
      flex-basis: calc(50% - 10px);
    }
    @media #{$mq-md} {
      flex-basis: calc(50% - 20px);
    }
  }

  a {
    display: block;
    margin-top: 8px;
    font-size: 13px;
  }
}
.contents-block.faq {
  .text-q {
    display: inline-block;
    margin-right: 8px;
    color: $blue;
  }
  .text-a {
    display: inline-block;
    margin-right: 8px;
    color: $pink;
  }
  .faq-group {
    & + .faq-group {
      margin-top: $margin-sm;
    }
  }
}