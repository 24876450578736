@charset "utf-8";

// @import "bootstrap";
// @import "lib/variable";
// @import "lib/common";
// @import "lib/core";

@import "lib/variable";
@import "lib/follow-second";
@import "lib/button";
@import "lib/support";
@import "lib/company";
@import "lib/english";
@import "lib/recruit";
@import "lib/contact";
@import "lib/privacy";
@import "lib/sitemap";
@import "lib/topics";

a {
  color: $base;
  &:hover {
    text-decoration: none;
  }

  // 画像は下線を消す（念のため）
  img {
    text-decoration: none;
  }
}

.sp-navi{
  @media #{$mq-md} {
    display: none;
  }
}

.contents-block {
  margin-bottom: $margin-md;
  font-size: 13px;
  @media #{$mq-md} {
    margin-bottom: $margin-lg;
    font-size: 14px;
  }
}

// icon
.pdf i, .pdf span.icon {
  color: #dd0000;
  margin: 0 10px;
}
.link i, .link span.icon {
  color: $blue;
  margin: 0 10px;
}

// アンカー用のspan
.anker {
  display: block;
  margin-top: -100px;
  padding-top: 100px;
}

// =========================================================================
// headline
// =========================================================================

// h3
.glay_back {
  margin-bottom: 10px;
  padding: 12px;
  background-color: $gray;
  color: #fff;
  letter-spacing: 1px;
  font-size: 15px;
  @media #{$mq-sm} {
    padding: 14px 12px;
    font-size: 16px;
  }
}
.gray_border {
  border-bottom: solid 1px $border;
  text-align: center;
  padding: 16px 6px;
  margin-bottom: 30px;
  font-size: 17px;
  @media #{$mq-md} {
    text-align: left;
  }
}

// h4
.border_dotted {
  border-bottom: dotted 1px $gray;
  margin: {
    top: 20px;
    bottom: 10px;
  }
  padding-bottom: 5px;
  @media #{$mq-sm} {
    margin-bottom: 20px;
  }
}
.margin_headline {
  margin: 10px 0 20px 0;
}

// =========================================================================
// label
// =========================================================================
.label {
  &.label-yellow {
    background-color: $yellow;
  }
}

// =========================================================================
// text align
// =========================================================================
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

// =========================================================================
// font-color
// =========================================================================
.text-red {
  color: $red;
}
.text-blue {
  color: $blue;
}
.text-turquoise {
  color: $turquoise;
}
.text-yellow {
  color: $yellow;
}
.text-orange {
  color: $orange;
}
.text-violet {
  color: $violet;
}
.text-pink {
  color: $pink;
}
.text-aquamarine {
  color: $aquamarine;
}

// =========================================================================
// 404
// =========================================================================
.error404-block {
  text-align: center;
  .text-en {
    font-size: 12px;
  }

  .button-group {
    margin-top: $margin-sm;
  }
}