@charset "utf-8";

// ================================================
//
// second.cssの修正用シート
//
// ================================================

// header補助
.head {
  a:hover {
    cursor: pointer;
  }
}

// パンくずリスト
.pan br {
  display: none;
}

// search_products
#pro_index .title {
  cursor: pointer;
  background: #9b9b9b url('/images/second/index_service_btn_off.png') calc(100% - 12px) center no-repeat;
}
#pro_index .panel {
  display: none;
}
#pro_index .title.active {
  background: #9b9b9b url('/images/second/index_service_btn_on.png') calc(100% - 12px) center no-repeat;
}

// topics
.topics-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span.date {
    margin: 0;
    padding: 0;
    background: none;
    color: $light;
    letter-spacing: 1px;
  }
}

// 利用分野から探す サブカテゴリ
.field_list .link {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.field_list .link div {
  @media #{$mq-sm} {
    flex-basis: calc(50% - 5px);
  }
}

.bnr3 {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    margin: {
      right: -4px;
      left: -4px;
    };
  }
}

.bnr3 a {
  @media #{$mq-sm} {
    margin: 0 4px 15px 4px;
  }
}

// 製品一覧
.pro_index .flexbox {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & > div {
    @media #{$mq-sm} {
      flex-basis: calc(50% - 15px);
    }
  }
}

// 利用分野から探す
.field_index .flexbox {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & > div {
    @media #{$mq-sm} {
      flex-basis: calc(50% - 15px);
    }
  }
}

// 分野・アプリケーションから探す
.app_index .flexbox {
  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: {
      right: -15px;
      left: -15px;
    };
  }
  & > div {
    @media #{$mq-sm} {
      flex-basis: calc(33.33% - 30px);
      margin: {
        right: 15px;
        left: 15px;
      };
    }
  }
}
