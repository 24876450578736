@charset "utf-8";

// 個人情報保護について
.privacy-block {
  margin-top: $margin-sm;
  h3 {
    margin: 10px 0;
    padding: 0 5px 10px 5px;
    border-bottom: 1px solid $gray;
  }
  p {
    margin-top: 20px;
  }
}
.privacy-list {
  margin: 1em 0;
  li {
    margin: 5px 0;
    padding-left: 1.5em;
    list-style-type: none;
    background: url('/images/icon_arrow2.png') top 5px left 5px no-repeat;
  }
}